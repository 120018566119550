body, html, .fixedBody {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 250px;
  background-color: lightgreen;
  overflow: auto;
}

.input-lg {
  font-size: 16px;
  height: 40px !important;
  line-height: 40px !important;
}

.content {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em 2em 1em;
  overflow: auto;
}

.loginFormWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.loginForm {
  background-color: white;
  border-radius: 25px;
  padding: .3em 1em 0 1em;
  width: 350px;
  overflow: auto;
}

.settingsFormWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.settingsForm {
  max-width: 350px;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: auto;
}

.transTableWrapper {
  display: block;
  width: 100%;
  max-width: 900px;
  overflow: auto;
}

.transTable {
  border: 1px solid black;
}

.tableHeader {
  margin-bottom: 1em;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableHeader > .right {
  text-align: right;
}

.transactionPagination {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

.modal {
  display: flex;
  position: absolute;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.noShow {
  display: none !important;
}

.tranChart {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2em;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

